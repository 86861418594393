import React, { useState, useRef, useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { parseACF } from '../utils'
import DefaultPage from '../templates/default-page'
import { useMount } from 'react-use'
import { sliderContext } from '../components/ImageSlider/SliderProvider'

const PageNotFound = props => {
    const { active, setActive } = useContext(sliderContext)

    const data = {
        title: 'Page not found',
        slug: '/404',
        content: '<p>Page not found</p>'
    }

    useMount(() => {
        if (!active) {
            setActive(true)
        }
    })

    return (
        <DefaultPage pageData={data} />
    )
}

export default PageNotFound
